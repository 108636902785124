import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import AugmentedRealityContent from "../../../content/services/AugmenteedReality.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const AutgmentedReality = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Augmented Reality</title>
        <meta name="description" content="Explore the diverse applications of augmented reality (AR) in gaming and entertainment, retail and e-commerce, education and training, architecture and interior design, maintenance and repair, tourism and travel, and real estate. Learn how AR enhances user experiences and facilitates interactive engagement across various industries and everyday life." />
        <meta name="keywords" content="augmented reality, AR, gaming, entertainment, retail, e-commerce, education, training, architecture, interior design, maintenance, repair, tourism, travel, real estate" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}













                      <br />
                      <h3><strong>Transform Your Business with Augmented Reality by FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we bring <strong>augmented reality (AR)</strong> solutions to life, helping businesses enhance
                        training, improve maintenance, and deliver engaging customer experiences. Our AR technology overlays digital information
                        onto the physical world, creating interactive and immersive environments.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>What is Augmented Reality?</strong></h3>
                      <br />

                      <p>
                        <strong>Augmented Reality</strong> blends virtual content with the real world, enhancing what you see, hear, or experience.
                        With AR, you can visualize complex data, interact with digital tools, or train workers in a more engaging way, all through
                        devices like smartphones, tablets, or AR glasses.
                      </p>

                      <img src="assets/img/services_images/augmented_reality_new.png" alt="Augmented Reality Example" />

                      <hr />

                      <br />
                      <h3><strong>Benefits of Augmented Reality</strong></h3>
                      <br />

                      <ul>
                        <li><strong>Enhanced Training</strong>: AR can provide workers with real-time, hands-on training, guiding them step-by-step through tasks.</li>
                        <li><strong>Better Maintenance</strong>: Technicians can see detailed overlays on machines, making it easier to diagnose issues and carry out repairs.</li>
                        <li><strong>Improved Customer Experience</strong>: AR adds value by allowing customers to visualize products in their own environments before purchase, leading to more informed decisions.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech for AR Solutions?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we design customized AR solutions that align with your specific needs. Whether you’re looking
                        to train employees, improve maintenance procedures, or deliver engaging experiences for your customers, we leverage AR
                        to provide powerful tools that bring efficiency and clarity.
                      </p>

                      <p><strong>Contact us</strong> to discover how augmented reality can transform the way you do business, making everyday tasks smarter and more interactive.</p>
















                    </p>
                  </div>

                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">

                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AutgmentedReality;
