import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import TrackingContent from "../../../content/services/Tracking.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const Tracking = () => {
  const { language, } = useContext(MainContext);
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Object Tracking</title>
        <meta name="description" content="Explore various applications of object tracking, including object tracking in surveillance systems, autonomous vehicles, robotics, sports analysis, augmented reality (AR), action recognition, multiple object tracking, and industrial automation. Learn how object tracking technology contributes to different industries and domains, providing valuable insights and enhancing various processes." />
        <meta name="keywords" content="object tracking, surveillance systems, autonomous vehicles, robotics, sports analysis, augmented reality, AR, action recognition, multiple object tracking, industrial automation" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1 + " " + serviceContent.titlePart2}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}













                      <br />
                      <h3><strong>Enhance Precision with Object Tracking from FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we create tailored object tracking solutions to meet your unique needs. Whether you’re
                        managing inventory in manufacturing, keeping track of livestock in agriculture, or monitoring vehicles in traffic
                        systems, our expertise in computer vision and AI provides smart, automated tracking that boosts efficiency and
                        decision-making.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>How Does Object Tracking Work?</strong></h3>
                      <br />

                      <p>
                        Object tracking is a process that allows machines to follow the movement of objects over time within a video or
                        real-world environment. Unlike object detection, which identifies objects in a single frame, tracking monitors their
                        position and movement across multiple frames, providing valuable data about speed, direction, and behavior.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Motivational Example: Tracking Parts Movement in Industrial Operations</strong></h3>
                      <br />

                      <img src="assets/img/services_images/object_tracking.gif" alt="Object tracking example for industrial operations" />

                      <p>
                        Imagine a robotic arm in an industrial setting that moves parts from one location to another. Object tracking allows
                        the system to monitor the exact position of the parts and the arm throughout the entire process, ensuring each movement
                        is precise and efficient. This technology helps optimize operations, reduce the risk of misplaced components, and
                        maintain high accuracy in handling tasks. Similar tracking capabilities are also transforming other fields, such as
                        agriculture and traffic management, to improve productivity and resource management.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>FainTech’s Expertise Across Industries</strong></h3>
                      <br />

                      <hr />

                      <br />
                      <h4><strong>Industrial Automation and Safety</strong></h4>
                      <br />

                      <p>
                        In manufacturing, object tracking helps monitor the flow of parts and products along production lines, ensuring that
                        everything moves smoothly through different stages. It can also track worker movements to ensure safety protocols
                        are followed in hazardous areas.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We build systems that track the movement of objects on production lines, helping
                          to maintain a steady workflow and quickly identifying any delays or issues.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Tracking the movement of items on a conveyor belt to ensure smooth operations and prevent bottlenecks.</p>

                      <hr />

                      <br />
                      <h4><strong>Agricultural Monitoring</strong></h4>
                      <br />

                      <p>
                        In agriculture, object tracking is used to monitor the movement of livestock or machinery across large fields. This
                        allows farmers to ensure that animals are safe, track their grazing patterns, and monitor the activity of equipment
                        like tractors and harvesters.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: FainTech provides systems that track livestock or equipment movements, offering
                          farmers real-time insights to improve the management of their resources.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Tracking the movement of cattle in a pasture to ensure their safety and optimize grazing areas.</p>

                      <hr />

                      <br />
                      <h4><strong>Traffic Management and Road Safety</strong></h4>
                      <br />

                      <p>
                        For traffic management, object tracking can follow the movement of vehicles and pedestrians through intersections
                        and roads, providing cities with valuable data to improve traffic flow and enhance road safety. It’s especially
                        useful for adjusting traffic signals and detecting incidents in real time.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We create AI models that track vehicles and pedestrians in real time, helping cities
                          to optimize traffic patterns and reduce accidents.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Tracking vehicles through a roundabout to monitor peak traffic times and adjust signal timing
                        for better flow.</p>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we don’t just offer AI solutions—we work with you to create custom object tracking
                        systems that match your specific needs. Here’s why you should choose us:
                      </p>
                      <ul>
                        <li><strong>Custom Solutions</strong>: We build systems from scratch, ensuring they align with your goals and work perfectly in your environment.</li>
                        <li><strong>Advanced Technology</strong>: Our solutions use the latest computer vision and AI tools to provide high accuracy and reliability.</li>
                        <li><strong>Industry Expertise</strong>: With experience in agriculture, manufacturing, and traffic management, we know how to create solutions that help your business succeed.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>How FainTech Can Help You</strong></h3>
                      <br />

                      <p>
                        From the first idea to a working prototype and final product, <strong>FainTech</strong> supports you every step of the way.
                        We work closely with you, understanding your needs and building solutions that solve real-world challenges. Whether you
                        want to optimize production lines, monitor livestock, or improve traffic flow, we have the expertise to make it happen.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Get Started with FainTech Today</strong></h3>
                      <br />

                      <p>
                        Interested in how object tracking can transform your operations? <strong>Contact us</strong> at FainTech to explore
                        how we can build the right solution for your business. Let’s collaborate, innovate, and achieve success together!
                      </p>















                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Tracking;
