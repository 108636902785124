import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import DeepLearning3DContent from "../../../content/services/DeepLearning3d.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const DeepLearning3d = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Deep Learning On 3D Point Clouds</title>
        <meta name="description" content="Explore various applications of deep learning on 3D point clouds, including 3D object classification, object detection and segmentation, point cloud registration, LiDAR-based perception, point cloud segmentation and semantic labeling, gesture recognition, robotics, and more. Learn how deep learning techniques applied to point clouds contribute to autonomous driving, robotics, augmented reality, environmental monitoring, infrastructure inspection, and other fields." />
        <meta name="keywords" content="3D point clouds, deep learning, object classification, object detection, segmentation, point cloud registration, LiDAR-based perception, gesture recognition, robotics" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1 + " " + serviceContent.titlePart2}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}












                      <br />
                      <h3><strong>Advanced 3D Analysis with Deep Learning on Point Clouds</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we use deep learning to analyze <strong>3D point clouds</strong>, helping industries like
                        manufacturing, construction, and robotics understand the world in three dimensions. A <strong>point cloud</strong> is a
                        collection of data points in 3D space, captured by sensors like LiDAR or 3D scanners. These points give a detailed picture
                        of real-world objects and environments.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>What is Deep Learning on 3D Point Clouds?</strong></h3>
                      <br />

                      <p>
                        Deep learning on 3D point clouds helps machines understand complex 3D spaces by analyzing shapes, structures, and the
                        relationships between objects. Unlike regular 2D images, 3D point clouds show depth, allowing for more accurate identification
                        and analysis of objects.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>How 3D Point Cloud Analysis Can Help</strong></h3>
                      <br />

                      <ul>
                        <li><strong>Manufacturing</strong>: Inspecting complex parts, automating quality checks, and finding defects in 3D objects.</li>
                        <li><strong>Construction</strong>: Creating detailed 3D models of buildings or sites for progress tracking and safety checks.</li>
                        <li><strong>Robotics</strong>: Helping robots and self-driving vehicles understand and move through complex environments.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech for 3D Solutions?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we turn 3D point cloud data into valuable insights using the latest deep learning techniques.
                        Whether it’s improving inspections or helping robots navigate, we deliver accurate and innovative solutions for your needs.
                      </p>

                      <p><strong>Contact us</strong> to learn how we can help you with 3D analysis.</p>















                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DeepLearning3d;
