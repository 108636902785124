import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
// import ImageSegmentationContent from "../../../content/services/ImageSegmentation.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";

const RaspberryPiNoirCameraBlog = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Image Segmentation</title>
        <meta
          name="description"
          content="Explore various applications of image segmentation, including medical imaging, object recognition, scene understanding, video analysis, satellite imaging, and industrial quality control. Learn how image segmentation technology contributes to different fields such as healthcare, robotics, surveillance, and environmental monitoring."
        />
        <meta
          name="keywords"
          content="image segmentation, medical imaging, object recognition, scene understanding, video analysis, satellite imaging, industrial quality control, healthcare, robotics, surveillance, environmental monitoring"
        />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                  <h1 className="large-heading">Exploring ANPR with the Raspberry Pi NoIR Camera</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}
                      {/* FROM HERE */}
                      <br />
                      <p>
                        <strong>
                          In this post, we&rsquo;ll explore how to use the{" "}
                          <strong>Raspberry Pi NoIR Camera Module V2.1</strong>{" "}
                          with a <strong>Raspberry Pi 3 Model B</strong> to
                          build a simple{" "}
                          <strong>
                            Automatic Number Plate Recognition (ANPR)
                          </strong>{" "}
                          system. We'll look at how this setup can detect and
                          read license plates, even in low-light conditions,
                          using object detection and OCR (Optical Character
                          Recognition).
                        </strong>
                      </p>
                      <br />
                      <br />
                      <img
                        src="assets/img/blogs_images/Pasted_image_20241011150618.png"
                        alt="Raspberry Pi NoIR Camera Module"
                      />
                      <br/>
                      <br/>
                      <br />
                      <h2>Meet the Raspberry Pi NoIR Camera Module V2.1</h2>
                      <br />
                      <p>
                        The Raspberry Pi NoIR Camera v2 is the official "night
                        vision" camera board from the Raspberry Pi Foundation.
                        It features an 8-megapixel Sony IMX219 sensor, which can
                        capture clear, high-resolution photos at 3280 x 2464
                        pixels and record videos up to 1080p at 30fps.
                      </p>
                      <br />
                      <img
                        src="assets/img/blogs_images/Pasted_image_20241011150705.png"
                        alt="Raspberry Pi NoIR Camera"
                      />
                      <br/>
                      <br/>
                      <p>
                        What makes this camera special is that it doesn&rsquo;t
                        have an infrared filter, allowing it to work well with
                        infrared light. This makes it perfect for projects that
                        need to capture images in the dark&mdash;like night-time
                        photography or low-light surveillance.
                      </p>
                      <ul>
                        <li>8-megapixel resolution for high-quality images.</li>
                        <li>
                          A fixed-focus lens with a focus distance of about 50
                          cm.
                        </li>
                        <li>
                          Compatibility with various Raspberry Pi models,
                          including the 3B, Zero 2, and the new Raspberry Pi 5
                          (with an adapter).
                        </li>
                        <li>
                          A lightweight, compact design, making it easy to
                          attach and use.
                        </li>
                      </ul>
                      <br />
                      <h2>Building an ANPR System</h2>
                      <br />
                      <p>
                        The goal is to build an ANPR system that can detect and
                        read license plates. Here&rsquo;s a step-by-step look at
                        how we do this:
                      </p>
                      <ol>
                        <li>
                          <strong>Detect the License Plates</strong>: We start
                          by using an object detection model to find license
                          plates in an image.
                        </li>
                        <li>
                          <strong>Read the Text with OCR</strong>: For these
                          front-facing images, we can then use OCR to read the
                          text directly from the license plate.
                        </li>
                      </ol>
                      <p>
                        The task appears easy when the camera is positioned
                        directly in front of the car. But what happens when the
                        license plate is not directly facing the camera, like
                        when a car is parked parallel? Let&rsquo;s see how to
                        handle that.
                      </p>
                      <br />
                      <h2>Camera Positioned Directly in Front of the Car</h2>
                      <br />
                      <p>
                        In this scenario, the camera is positioned directly in
                        front of the car, capturing a clear view of the license
                        plate.
                      </p>
                      <p>
                        <strong>We start with the original image:</strong>
                      </p>
                      <br />
                      <img
                        src="assets/img/blogs_images/2024-09-05_10-46-27.jpg"
                        alt="Original Image of License Plate"
                      />
                      <br/>
                      <br/>
                      <p>
                        Next, we apply object detection to identify the license
                        plate. The visualization below shows the bounding box
                        around the detected license plate:
                      </p>
                      <br />
                      <img
                        src="assets/img/blogs_images/Pasted_image_20241011142149.png"
                        alt="Bounding Box on License Plate"
                      />
                      <br/>
                      <br/>
                      <p>
                        Finally, we perform Optical Character Recognition (OCR)
                        on the identified license plate. Here is the result of
                        the OCR process:
                      </p>
                      <br />
                      <img
                        src="assets/img/blogs_images/Pasted_image_20241011142246.png"
                        alt="OCR Result"
                      />
                      <br/>
                      <br/>
                      <p>
                        As we can see, the OCR has successfully extracted the
                        license plate number from the image.
                      </p>
                      <br />
                      <h2>Reading License Plates from Side Angles</h2>
                      <br />
                      <p>
                        In the second scenario, the camera is placed on the
                        sidewalk, capturing the license plates from a difficult
                        angle.
                      </p>
                      <br />
                      <img
                        src="assets/img/blogs_images/side_plate.png"
                        alt="License Plate Side Angle"
                      />
                      <br/>
                      <br/>
                      <p>
                        When the camera captures the license plate from the
                        side, we need to take a few more steps:
                      </p>
                      <ol>
                        <li>
                          <strong>Detect the Plate</strong>: First, we still
                          detect the license plate with our object detection
                          model.
                        </li>
                        <br />
                        <img
                          src="assets/img/blogs_images/Pasted_image_20241011143631.png"
                          alt="Detected License Plate"
                        />
                        <br/>
                        <br/>
                        <li>
                          <strong>Segment the Image</strong>: Next, we perform
                          image segmentation to isolate the license plate area
                          by creating a mask. This mask helps us focus
                          specifically on the plate. We also use the detected
                          bounding box to guide the segmentation process,
                          ensuring we extract the correct mask around the
                          license plate.
                        </li>
                        <br />
                        <img
                          src="assets/img/blogs_images/new_segm.png"
                          alt="Segmented License Plate"
                        />
                        <br/>
                        <br/>
                        <li>
                          <strong>Find the Edges and Corners</strong>: Using
                          this mask, we find the edges of the license plate and
                          pinpoint its corners.
                        </li>
                        <br />
                        <img
                          src="assets/img/blogs_images/Pasted_image_20241011143729.png"
                          alt="Edges and Corners of License Plate"
                        />
                        <br/>
                        <br/>
                        <li>
                          <strong>Adjust the Perspective</strong>: We then
                          straighten out the license plate&rsquo;s perspective,
                          turning it from a slanted shape into a straight
                          rectangle. This step makes the letters and numbers
                          easier to read.
                        </li>
                        <br />
                        <img
                          src="assets/img/blogs_images/Pasted_image_20241011143757.png"
                          alt="Adjusted Perspective of License Plate"
                        />
                        <br/>
                        <br/>
                        <li>
                          <strong>Read the Adjusted Image</strong>: Finally, we
                          apply OCR to this adjusted image to extract the text
                          from the license plate.
                        </li>
                        <br />
                        <img
                          src="assets/img/blogs_images/Pasted_image_20241011143829.png"
                          alt="OCR of Adjusted License Plate"
                        />
                        <br/>
                        <br/>
                      </ol>
                      <br />
                      <h2>Using the NoIR Camera in the Dark</h2>
                      <br />
                      <p>
                        One of the best things about the Raspberry Pi NoIR
                        Camera is how well it works in the dark when paired with
                        an infrared light source. We can capture images even in
                        complete darkness, making it great for situations where
                        we need to see clearly at night.
                      </p>
                      <br />
                      <img
                        src="assets/img/blogs_images/night-vision.png"
                        alt="Night Vision with NoIR Camera"
                      />
                      <br/>
                      <br/>
                      <p>
                        As we can see, the camera is able to successfully
                        capture clear images of text in a pitch-dark environment
                        using infrared light, allowing us to read the text
                        without any visible light at all. This makes it an ideal
                        choice for night-time ANPR systems.
                      </p>
                      <br />
                      <h2>
                        Conclusion: The Raspberry Pi NoIR Camera's Potential
                      </h2>
                      <br />
                      <p>
                        The Raspberry Pi NoIR Camera Module V2.1, paired with
                        the Raspberry Pi 3B, is a great option for building an
                        ANPR system. It&rsquo;s easy to set up, works well in
                        different lighting conditions, and is capable of
                        capturing high-quality images. By combining object
                        detection, image segmentation, and OCR, we can
                        accurately read license plates, even when the angle is
                        challenging.
                      </p>
                      <p>
                        This mini-project shows how the Raspberry Pi NoIR Camera
                        can be a powerful tool for building computer vision
                        projects. Whether it&rsquo;s for ANPR, night-time
                        surveillance, or just exploring the possibilities of
                        infrared photography, this setup offers a lot of
                        potential.
                      </p>

                      {/* TO HERE */}
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}*/}
                  {/*></div>*/}
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            {/*<div*/}
            {/*  className="ptf-spacer"*/}
            {/*  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}*/}
            {/*></div>*/}
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <p className="fz-18">
                      {/*{ImageSegmentationContent.description_long[language]}*/}
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "4.375rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/*<h3>{ServicesLabels.applications[language]}</h3>*/}
                    {/* <!--Spacer--> */}
                    {/*<div*/}
                    {/*  className="ptf-spacer"*/}
                    {/*  style={{ "--ptf-xxl": "1.875rem" }}*/}
                    {/*></div>*/}
                    {/*<p className="fz-18">*/}
                    {/*  {ImageSegmentationContent.applications.map((item, i) => (*/}
                    {/*      <p dangerouslySetInnerHTML={{__html: item[language]}}/>*/}
                    {/*    ))}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Animated Block--> */}
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div className="ptf-animated-block" data-aos-delay="0">
                    <h3>{ServicesLabels.our_approach[language]}</h3>
                    {/* <!--Spacer--> */}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default RaspberryPiNoirCameraBlog;
