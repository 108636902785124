import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
// import ImageClassificationContent from "../../../content/services/ImageClassification.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const ImageClassification = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Image Classification</title>
        <meta name="description" content="Explore various applications of image classification, including autonomous vehicles, healthcare, e-commerce, security, agriculture, and wildlife conservation. Learn how image classification technology impacts different industries." />
        <meta name="keywords" content="image classification, autonomous vehicles, healthcare, e-commerce, security, surveillance, agriculture, wildlife conservation" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1 + " " + serviceContent.titlePart2}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}













                      <br />
                      <h3><strong>Discover the Benefits of Image Classification with FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we create personalized image classification solutions designed to fit your business.
                        Whether you're improving industrial processes, transforming agriculture, or optimizing traffic systems, our skills
                        in computer vision and AI bring smart, automated solutions that boost accuracy and efficiency in your operations.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>How Does Image Classification Work?</strong></h3>
                      <br />

                      <p>
                        Image classification allows machines to "see" and understand what's in an image by analyzing patterns and features.
                        It’s not just about recognizing objects—it's about making your systems smarter, faster, and more efficient.
                      </p>

                      <p><strong>Motivational Example: Cat vs. Dog Classification</strong></p>
                      <img src="assets/img/services_images/cat_dog_classification.png" alt="Cat vs Dog Classification Example" />

                      <p>
                        To simplify, think of the task as showing a computer images of cats and dogs. The computer learns to differentiate
                        between them by analyzing shapes, colors, and textures. Similarly, this technology can be applied in complex
                        industries like manufacturing, agriculture, and traffic.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>FainTech's Expertise Across Industries</strong></h3>
                      <br />

                      <br />
                      <h4><strong>Industrial Quality Control</strong></h4>
                      <br />

                      <p>
                        For industrial applications, our image classification technology ensures precision in quality control. Cameras
                        installed on assembly lines can automatically detect defects in products, allowing for faster production and
                        fewer mistakes.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We create custom quality control systems that classify products as “Defective” or
                          “Non-defective” in real-time, minimizing errors and enhancing efficiency.
                        </li>
                      </ul>

                      <p><strong>Example: The industrial assembly line image with defective and non-defective labels.</strong></p>
                      <img src="assets/img/services_images/Pasted image 20241004090112.png" alt="Industrial assembly line image" />

                      <hr />

                      <br />
                      <h4><strong>Agricultural Automation</strong></h4>
                      <br />

                      <p>
                        Image classification is changing the way farmers check crop health, manage livestock, and improve yields. With
                        the help of cameras, our systems assess plant health and spot early signs of disease, helping farmers save money
                        and boost productivity.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: FainTech offers automated crop monitoring systems that identify healthy and
                          diseased plants, giving farmers useful information to improve their farming practices.
                        </li>
                      </ul>

                      <hr />

                      <br />
                      <h4><strong>Traffic Management and Smart Cities</strong></h4>
                      <br />

                      <p>
                        Our image classification systems help manage traffic flow, enforce regulations, and increase road safety. By
                        installing smart cameras in urban areas, traffic authorities can detect different types of vehicles and pedestrians,
                        recognize traffic signals, and even identify unsafe behaviors.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We build AI models that recognize cars, buses, pedestrians, and other elements
                          to help improve traffic flow and reduce accidents.
                        </li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we’re more than just an AI consultancy. We specialize in creating custom image
                        classification solutions that match your unique business needs. Here’s why partnering with us is the right choice:
                      </p>
                      <ul>
                        <li><strong>Custom Solutions</strong>: We start from scratch, building systems that perfectly fit your specific environment and goals.</li>
                        <li><strong>Latest Technology</strong>: Our solutions use cutting-edge computer vision and AI technology to provide exceptional accuracy and reliability.</li>
                        <li><strong>Expertise Across Industries</strong>: With proven experience in agriculture, industrial processes, and traffic safety, we know how to help businesses like yours succeed.</li>
                      </ul>

                      <br />
                      <h3><strong>How FainTech Can Help You</strong></h3>
                      <br />

                      <p>
                        From initial idea to a working prototype and final product, we’re with you every step of the way. We’ll brainstorm
                        together, dive deep into your data, and help you turn insights into practical solutions. Whether you’re optimizing
                        traffic, automating agriculture, or improving production lines, we’re here to build smart, efficient systems that
                        reduce costs and improve decision-making.
                      </p>

                      <br />
                      <h3><strong>Get Started with FainTech Today</strong></h3>
                      <br />

                      <p>
                        Ready to see how image classification can transform your business? <strong>Contact us</strong> at FainTech to start
                        a conversation. Together, we’ll develop a solution that takes your operations to the next level. Let’s innovate,
                        collaborate, and build success together!
                      </p>

















                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            {/*<div*/}
            {/*  className="ptf-spacer"*/}
            {/*  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}*/}
            {/*></div>*/}
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/*<p className="fz-18">*/}
                    {/*  {ImageClassificationContent.description_long[language]}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "4.375rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/*<h3>{ServicesLabels.applications[language]}</h3>*/}
                    {/* <!--Spacer--> */}
                    {/*<div*/}
                    {/*  className="ptf-spacer"*/}
                    {/*  style={{ "--ptf-xxl": "1.875rem" }}*/}
                    {/*></div>*/}
                    {/*<p className="fz-18">*/}
                    {/*  {ImageClassificationContent.applications.map((item, i) => (*/}
                    {/*      <p dangerouslySetInnerHTML={{__html: item[language]}}/>*/}
                    {/*    ))}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Animated Block--> */}
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "6.875rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos-delay="0"
                  >
                    <h3>{ServicesLabels.our_approach[language]}</h3>
                    {/* <!--Spacer--> */}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ImageClassification;
