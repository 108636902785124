import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import CameraCalibrationContent from "../../../content/services/CameraCalibration.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const CameraCalibration = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Camera Calibration - Stereo Calibration - HandEye Calibration</title>
        <meta name="description" content="Explore the diverse applications of camera calibration in computer vision, including 3D reconstruction, object tracking, augmented reality (AR), structure from motion (SfM), image stitching, panoramic imaging, and visual odometry. Learn how camera calibration enables accurate perception, tracking, and various computer vision tasks across multiple domains." />
        <meta name="keywords" content="camera calibration, computer vision, 3D reconstruction, object tracking, augmented reality, AR, structure from motion, SfM, image stitching, panoramic imaging, visual odometry" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}













                      <br />
                      <h3><strong>Achieve Precision with Camera Calibration by FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we specialize in <strong>camera calibration</strong> to ensure accurate measurements and
                        reliable performance in your vision systems. Whether you’re using cameras for industrial automation, agricultural monitoring,
                        or safety systems, calibration is essential to guarantee consistent results.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>What is Camera Calibration?</strong></h3>
                      <br />

                      <p>
                        <strong>Camera calibration</strong> is the process of determining the camera's internal parameters, such as focal length,
                        lens distortion, and alignment. Proper calibration corrects image distortions and ensures that measurements taken from
                        images are accurate. This is crucial in applications like 3D reconstruction, precision inspection, and autonomous navigation.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Benefits of Camera Calibration</strong></h3>
                      <br />

                      <ul>
                        <li><strong>Accurate Measurements</strong>: Calibration ensures that distance, size, and other measurements derived from images are reliable.</li>
                        <li><strong>Reduced Distortion</strong>: Correcting lens distortion helps maintain the true shape and position of objects, improving the accuracy of computer vision systems.</li>
                        <li><strong>Enhanced Performance</strong>: Well-calibrated cameras provide consistent data for applications like object detection, tracking, and inspection.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech for Camera Calibration?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we use advanced techniques to calibrate your cameras for the best performance. Whether you're
                        working with single or multi-camera setups, we ensure your vision systems are accurately calibrated for the job, delivering
                        precision and reliability every time.
                      </p>

                      <p><strong>Contact us</strong> to learn how we can help fine-tune your camera systems for your specific needs.</p>

















                    </p>
                  </div>
                  
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">

                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CameraCalibration;
