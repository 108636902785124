import React, {useContext} from "react";
import { Link } from "react-router-dom";
import {MainContext} from "../../../context/MainContext";

const menuContent = [
  {
    name: {
      "no": "Hjem",
      "en": "Home"
    },
    activeClass: "sf-with-ul",
    menuClass: "current-menu-item",
    routerPath: "/"
  },
  {
    name: {
      "no": "Tjenester",
      "en": "Services"
    },
    activeClass: "",
    menuClass: "",
    routerPath: "/services"
  },
  {
    name: {
      "no": "Blog",
      "en": "Blog"
    },
    activeClass: "",
    menuClass: "",
    routerPath: "/blog"
  },
  {
    name: {
      "no": "Kontakt oss",
      "en": "Contact us"
    },
    activeClass: "",
    menuClass: "",
    routerPath: "/contact"
  },
  {
    name: {
      "no": "V\u00e5rt Team",
      "en": "Our Team"
    },
    activeClass: "",
    menuClass: "",
    routerPath: "/our-team"
  }
];

const DropdownMenu = () => {
  const { language,  } = useContext(MainContext);

  return (
    <ul className="sf-menu">
      {menuContent.map((item, i) => (
        <li className={`menu-item-has-children ${item.menuClass}`} key={i}>
          <Link to={item.routerPath}>
            {" "}
            <span>{item.name[language]}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default DropdownMenu;
