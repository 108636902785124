import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import IndustrialInspectionContent from "../../../content/services/IndustrialInspection.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const IndustrialInspection = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Industrial Visual Inspection With/Without CAD</title>
        <meta name="description" content="Explore the diverse applications of computer vision in industrial inspection, including defect detection, dimensional measurement, object recognition and sorting, surface inspection, assembly verification, traceability, and quality control. Learn how computer vision transforms industrial processes by improving quality, efficiency, and productivity." />
        <meta name="keywords" content="computer vision, industrial inspection, defect detection, dimensional measurement, object recognition, sorting, surface inspection, assembly verification, traceability, quality control" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}















                      <br />
                      <h3><strong>Optimize Quality Control with Industrial Visual Inspection by FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we provide advanced solutions for <strong>industrial visual inspection</strong>, using
                        both CAD-assisted and non-CAD methods to enhance quality control and ensure precision in manufacturing. Our systems
                        bring efficiency, accuracy, and cost savings to production lines.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>What is Industrial Visual Inspection with/without CAD?</strong></h3>
                      <br />

                      <p>
                        <strong>Visual inspection</strong> is a quality control process where AI systems analyze products to identify defects
                        or deviations. When combined with <strong>CAD models</strong>, the inspection can be even more precise—comparing the
                        product against its original design to detect discrepancies. Without CAD, the system learns from real-world examples
                        to recognize what’s correct and what’s defective, offering a flexible approach to quality control.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Benefits of Visual Inspection</strong></h3>
                      <br />

                      <ul>
                        <li><strong>CAD-Assisted Precision</strong>: Using CAD models allows for exact comparison, ensuring products match their design specifications perfectly.</li>
                        <li><strong>Flexible Learning</strong>: Without CAD, visual inspection systems can learn from samples, adapting quickly to new products or designs.</li>
                        <li><strong>Reduced Waste</strong>: Early detection of defects minimizes waste by allowing corrections before production reaches advanced stages.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech for Visual Inspection?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we develop visual inspection solutions to fit your needs, whether you're using CAD models
                        for detailed accuracy or training systems from real-world samples for flexible quality control. Our technology helps
                        reduce errors, improve product quality, and boost efficiency in manufacturing.
                      </p>

                      <p><strong>Contact us</strong> to learn how our visual inspection solutions can help maintain the highest quality standards in your production processes.</p>




















                    </p>
                  </div>
                  
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">

                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default IndustrialInspection;
