import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
// import ObjectDetectionContent from "../../../content/services/ObjectDetection.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const ObjectDetection = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Object Detection</title>
        <meta name="description" content="Explore various applications of object detection, including autonomous vehicles, video surveillance, retail analytics, robotics, augmented reality, medical imaging, and industrial automation. Learn how object detection technology contributes to different industries such as automotive, retail, healthcare, and manufacturing." />
        <meta name="keywords" content="object detection, autonomous vehicles, video surveillance, retail analytics, robotics, augmented reality, medical imaging, industrial automation, automotive, retail, healthcare, manufacturing" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1 + " " + serviceContent.titlePart2}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}












                      <br />
                      <h3><strong>Unleash the Potential of Object Detection with FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we provide custom object detection solutions designed to meet the unique needs of your business.
                        Whether you're streamlining production in manufacturing, optimizing agricultural operations, or improving traffic systems,
                        our expertise in computer vision and AI brings smart, automated solutions that enhance efficiency and precision.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>How Does Object Detection Work?</strong></h3>
                      <br />

                      <p>
                        Object detection allows machines to not only recognize but also locate objects in an image. It’s more than identifying what’s
                        in the image—it determines the exact position of each object, making your systems smarter and more capable of understanding
                        complex environments.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Motivational Example: Detecting Foreign Objects in Crop Fields</strong></h3>
                      <br />

                      <p>
                        Imagine a smart camera system installed on farming equipment, scanning fields for foreign objects like rocks or plastic
                        that could damage machinery during harvesting. Object detection enables farmers to automatically identify and avoid these
                        obstacles, protecting their equipment and preventing costly downtime. This not only reduces risks but also enhances
                        operational efficiency, ensuring smoother and safer harvesting processes. The same object detection technology is also
                        revolutionizing industries like manufacturing and traffic management.
                      </p>

                      <p><strong>Example</strong>: Detecting ripe fruits in an orchard, allowing automated harvesting systems to pick only the fruits
                        ready for harvest.</p>
                      <img src="assets/img/services_images/Pasted image 20241008121121.png" alt="Detecting ripe fruits in an orchard" />

                      <hr />

                      <br />
                      <h3><strong>FainTech’s Expertise Across Industries</strong></h3>
                      <br />

                      <hr />

                      <br />
                      <h4><strong>Industrial Automation and Safety</strong></h4>
                      <br />

                      <p>
                        In manufacturing, object detection ensures that machines can recognize and handle objects accurately, improving both
                        production efficiency and workplace safety. This technology can detect tools, machinery parts, or even the presence
                        of workers, enabling smarter automation and hazard detection.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We develop systems that detect and locate objects on the production line, identifying
                          parts that need assembly, or signaling potential safety hazards.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Detecting different components on an assembly line, ensuring proper placement and alerting operators
                        of any missing or misaligned parts.</p>

                      <hr />

                      <br />
                      <h4><strong>Agricultural Innovation</strong></h4>
                      <br />

                      <p>
                        In agriculture, object detection can identify diseased or infected plants early, allowing farmers to take timely action
                        to protect their crops. This technology helps pinpoint areas affected by pests or diseases, enabling precise interventions
                        such as targeted pesticide use or plant removal to prevent further spread.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: FainTech provides automated systems that detect signs of disease or infection in crops
                          by analyzing plant characteristics such as leaf discoloration or abnormal growth, allowing farmers to address
                          problems before they escalate.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Detecting signs of infection in crops, enabling farmers to apply treatments only where needed,
                        reducing pesticide use and improving overall crop health.</p>
                      <img src="assets/img/services_images/Pasted image 20241008121424.png" alt="Detecting signs of infection in crops" />

                      <hr />

                      <br />
                      <h4><strong>Traffic Management and Road Safety</strong></h4>
                      <br />

                      <p>
                        For traffic management, object detection can identify vehicles, pedestrians, and traffic signs, helping cities better
                        manage roadways and reduce accidents. Smart cameras equipped with object detection can also provide real-time insights
                        for dynamic traffic control systems.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We create AI models that detect and locate objects in traffic scenes, allowing for
                          more efficient traffic flow and improved road safety.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Detecting vehicles at an intersection and adjusting traffic signals based on real-time traffic
                        conditions to optimize flow and reduce congestion.</p>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we’re not just an AI solutions provider—we’re your partner in building custom object
                        detection systems that meet your specific needs. Here’s why you should choose to work with us:
                      </p>
                      <ul>
                        <li><strong>Custom Solutions</strong>: We design systems from the ground up, ensuring they perfectly fit your requirements and environment.</li>
                        <li><strong>Latest Technology</strong>: Our solutions leverage the latest advancements in computer vision and AI, providing top-tier accuracy and reliability.</li>
                        <li><strong>Expertise Across Industries</strong>: With proven experience in agriculture, industrial processes, and traffic management, we deliver solutions that help businesses thrive.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>How FainTech Can Help You</strong></h3>
                      <br />

                      <p>
                        From the initial idea to a working prototype and final product, <strong>FainTech</strong> supports you every step
                        of the way. We work closely with you, analyzing data and building practical solutions that solve real-world challenges.
                        Whether you're improving production lines, enhancing farm operations, or optimizing traffic systems, we have the tools
                        and expertise to make it happen.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Get Started with FainTech Today</strong></h3>
                      <br />

                      <p>
                        Curious about how object detection can transform your operations? <strong>Contact us</strong> at FainTech to explore
                        how we can create the right solution for your business. Let’s collaborate, innovate, and achieve success together!
                      </p>













                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            {/*<div*/}
            {/*  className="ptf-spacer"*/}
            {/*  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}*/}
            {/*></div>*/}
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/* Comment out irelevant text */}
                    {/*<p className="fz-18">*/}
                    {/*  {ObjectDetectionContent.description_long[language]}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "4.375rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/*<h3>{ServicesLabels.applications[language]}</h3>*/}
                    {/* <!--Spacer--> */}
                    {/*<div*/}
                    {/*  className="ptf-spacer"*/}
                    {/*  style={{ "--ptf-xxl": "1.875rem" }}*/}
                    {/*></div>*/}
                    {/*<p className="fz-18">*/}
                    {/*  {ObjectDetectionContent.applications.map((item, i) => (*/}
                    {/*      <p dangerouslySetInnerHTML={{__html: item[language]}}/>*/}
                    {/*    ))}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Animated Block--> */}
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "6.875rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos-delay="0"
                  >
                    <h3>{ServicesLabels.our_approach[language]}</h3>
                    {/* <!--Spacer--> */}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ObjectDetection;
