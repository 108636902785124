import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import ObjectCountingContent from "../../../content/services/ObjectCounting.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const ImageSegmentation = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Object Counting</title>
        <meta name="description" content="Explore various applications of object counting, including crowd management, traffic monitoring, public transportation, manufacturing, wildlife conservation, security, agriculture, and farming. Learn how object counting technology contributes to different industries and domains, providing valuable insights for decision-making processes." />
        <meta name="keywords" content="object counting, crowd management, traffic monitoring, public transportation, manufacturing, wildlife conservation, security, agriculture, farming" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1 + " " + serviceContent.titlePart2}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}











                      <br />
                      <h3><strong>Maximize Efficiency with Object Counting from FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we develop custom object counting solutions to help your business boost productivity and
                        optimize processes. Whether you’re managing inventory in manufacturing, monitoring crop yields in agriculture, or
                        supervising traffic flow, our computer vision and AI expertise delivers accurate and automated object counting systems
                        that enhance decision-making and efficiency.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>How Does Object Counting Work?</strong></h3>
                      <br />

                      <p>
                        Object counting allows machines to automatically count individual objects within an image or video stream. This technology
                        can be used to monitor quantities in real-time, providing precise and actionable insights for various industries, from
                        tracking product output in a factory to counting vehicles on the road.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Motivational Example: Counting Vehicles on the Street</strong></h3>
                      <br />

                      {/* <img src="assets/img/services_images/object_counting.gif" alt="Object counting example for vehicles on the street" /> */}
                      <img style={{ display: 'block', margin: 'auto' }} src="assets/img/services_images/object_counting.gif" alt="Object counting example for vehicles on the street" />
                      <br />

                      <p>
                        Imagine a smart camera system installed at a busy intersection, automatically counting every vehicle that passes through.
                        Object counting enables cities to gather accurate traffic data, helping to adjust signal timing, manage congestion, and
                        plan infrastructure improvements. This technology helps streamline traffic management, reduce delays, and enhance road
                        safety. Similar approaches are transforming other fields like manufacturing and agriculture as well.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>FainTech’s Expertise Across Industries</strong></h3>
                      <br />

                      <hr />

                      <br />
                      <h4><strong>Industrial Production and Inventory Management</strong></h4>
                      <br />

                      <p>
                        In manufacturing, keeping an accurate count of products or parts is critical for maintaining smooth production and managing
                        inventory. Object counting ensures that every item produced is accounted for, and it can also monitor stock levels
                        automatically.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We develop systems that count objects on assembly lines or in storage, providing real-time
                          data on production output and inventory levels.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Counting parts on a production line to ensure accurate inventory and prevent stock shortages.</p>

                      <hr />

                      <br />
                      <h4><strong>Agricultural Yield Monitoring</strong></h4>
                      <br />

                      <p>
                        In agriculture, object counting helps farmers track the number of crops harvested, estimate yields, and monitor growth stages.
                        This technology is particularly useful for large-scale farms, where manual counting would be time-consuming and prone to error.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: FainTech provides systems that count harvested crops or plants, offering farmers accurate
                          data to manage resources and predict yields more effectively.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Counting harvested fruits or vegetables in real-time to provide precise yield estimates and
                        optimize the harvesting process.</p>

                      <hr />

                      <br />
                      <h4><strong>Traffic Flow and Management</strong></h4>
                      <br />

                      <p>
                        For traffic management, object counting can be used to monitor the number of vehicles or pedestrians passing through
                        intersections or specific road segments. This data is vital for improving traffic flow, adjusting signal timing, and
                        planning infrastructure upgrades.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We build AI models that count vehicles and pedestrians in real-time, helping cities manage
                          traffic more effectively and reduce congestion.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Counting cars at a busy intersection to optimize signal timing and improve traffic flow during
                        peak hours.</p>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we don’t just offer AI solutions—we work with you to create custom object counting systems
                        that match your specific needs. Here’s why you should work with us:
                      </p>
                      <ul>
                        <li><strong>Custom Solutions</strong>: We build systems from the ground up, making sure they fit your goals and environment.</li>
                        <li><strong>Advanced Technology</strong>: Our solutions use the latest computer vision and AI tools to deliver high accuracy and reliability.</li>
                        <li><strong>Industry Expertise</strong>: With experience in agriculture, manufacturing, and traffic management, we know how to create solutions that help your business grow.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>How FainTech Can Help You</strong></h3>
                      <br />

                      <p>
                        From the first idea to a working prototype and final product, <strong>FainTech</strong> is with you every step of the way.
                        We work closely with you, understanding your needs and building solutions that solve real problems. Whether you want to
                        improve production lines, track crop yields, or manage traffic, we have the skills to make it happen.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Get Started with FainTech Today</strong></h3>
                      <br />

                      <p>
                        Curious about how object counting can improve your business? <strong>Contact us</strong> at FainTech to talk about a solution
                        that fits your needs. Let’s work together to innovate and succeed!
                      </p>












                    </p>
                  </div>

                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">

                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ImageSegmentation;
