import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import HeaderDefault from "../../../components/header/HeaderDefault";
import NewsletterTwo from "../../../components/newsletter/NewsletterTwo";
import "./OurTeam.css";

const teamMembers = [
  {
    name: "Igor Jovančević",
    position: "Founder",
    description: "Igor Jovančević is a Professor at the University of Montenegro with a strong background in machine learning and computer vision. His research focuses on applying deep learning techniques to 3D point cloud analysis and industrial visual inspection, aiming to improve accuracy and efficiency in automated quality control systems. Igor is passionate about bridging the gap between cutting-edge research and practical applications, helping industries leverage AI to solve complex challenges.",
    image: "assets/img/team/Igor.jpeg"
  },
  {
    name: "Nikola Milović",
    position: "Tech Lead",
    description: "Nikola Milović is a Computer Vision Engineer and Tech Lead at FainTech, with a background in software development and a focus on artificial intelligence. He completed his Master's in Computer Science at the University of Montenegro, where he specialized in image processing and machine vision techniques. His professional journey includes working on the Roadguard project, which aimed at improving road safety through advanced computer vision methods. Nikola has hands-on experience in image classification, object detection, and semantic segmentation, consistently applying these skills to practical, industry-driven challenges.",
    image: "assets/img/team/Mili.jpeg"
  },
  {
    name: "Nikola Pižurica",
    position: "Machine Learning Engineer and Researcher",
    description: "Nikola Pižurica is a Computer Vision Engineer at FainTech and a Teaching Assistant at the University of Montenegro. He completed his Master's degree in Computer Science, focusing on the development of regularized convolutional encoder-decoder models for image-based surface defect detection in industry. Currently, he is pursuing a Ph.D. in Computer Science, where he continues to explore advanced applications of computer vision. His work is known for striking a balance between theoretical knowledge and practical application, ensuring that cutting-edge research is effectively translated into real-world solutions. Nikola has also contributed to projects like Roadguard AS, applying machine vision techniques to enhance road safety.",
    image: "assets/img/team/Dzooni.jpeg"
  },
  {
    name: "Milutin Kankaraš",
    position: "Machine Learning Engineer",
    description: "Milutin Kankaraš is a Computer Vision Engineer at FainTech and a Master's student at the University of Montenegro. After completing his Bachelor's degree in Computer Science, he is now diving deeper into advanced studies. Milutin is passionate about machine learning and computer vision, with a keen interest in understanding their mathematical foundations. He is also interested in exploring connections between natural sciences, mathematics, and computer science, aiming to discover new insights and practical applications that could push the boundaries of existing technology.",
    image: "assets/img/team/Comi.jpeg"
  },
  {
    name: "Ognjen Barović",
    position: "Machine Learning Engineer",
    description: "Ognjen Barović is a Software and Machine Learning Engineer at FainTech, currently pursuing a Master's degree in Computing and Information Technologies at the University of Montenegro. He is passionate about exploring advanced topics in machine learning and software development. With experience as a backend Java developer and a strong track record in hackathons, Ognjen is skilled at solving complex problems through innovative and practical approaches. He is committed to continuous learning and applying his expertise to create impactful and efficient solutions.",
    image: "assets/img/team/Barovic.jpg"
  }
];

const OurTeam = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--our-team">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Meet Our Team</title>
        <meta
          name="description"
          content="Get to know the talented individuals behind Faintech. Our team is dedicated to bringing you the best in AI, computer vision, and machine learning solutions."
        />
        <meta
          name="keywords"
          content="Faintech, AI team, computer vision experts, machine learning engineers, Faintech team"
        />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        <div className="main">
          <div className="ptf-page ptf-page--our-team">
            <section>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}></div>
              <div className="container-xxl">
                <div className="row justify-content-center">
                  <div className="col-xl-8 col-lg-10">
                    <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
                      <h1 className="large-heading fancy-heading text-center">Meet Our Team</h1>
                      <p className="fancy-intro text-center">
                        We’re the people behind the innovation, always pushing the boundaries of technology and creativity to bring you world-class AI and computer vision solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ptf-spacer" style={{ "--ptf-xxl": "4.375rem" }}></div>
              </div>
            </section>

            {/* Start Team Member Section */}
            <section className="fancy-team-section">
              <div className="ptf-spacer" style={{ "--ptf-xxl": "3.25rem", "--ptf-md": "2.125rem" }}></div>
              <div className="container-xxl">
                <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
                  <div className="row g-4">
                    {teamMembers.map((member, index) => (
                      <div key={index} className="col-lg-4 col-md-6">
                        <div className="ptf-team-member fancy-team-member">
                          <div className="ptf-team-member__image-wrapper">
                            <img src={member.image} alt={member.name} className="ptf-team-member__image" />
                          </div>
                          <div className="ptf-team-member__content">
                            <h3 className="ptf-team-member__name fancy-text">{member.name}</h3>
                            <p className="ptf-team-member__position fancy-position">{member.position}</p>
                            <p className="ptf-team-member__description fancy-description">{member.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}></div>
            </section>

            {/* Start Newsletter Section */}
            <section>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "9.375rem", "--ptf-md": "4.6875rem" }}></div>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
                      <h1 className="large-heading fancy-heading text-center">Newsletter</h1>
                      <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
                      <p className="fancy-intro text-center">
                        Stay updated with the latest innovations and insights from our talented team.
                      </p>
                    </div>
                    <div className="ptf-spacer" style={{ "--ptf-lg": "3.75rem", "--ptf-md": "1.875rem" }}></div>
                  </div>
                  <div className="col-lg-6 offset-lg-2">
                    <div className="ptf-animated-block" data-aos="fade" data-aos-delay="100">
                      <NewsletterTwo />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ptf-spacer" style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}></div>
            </section>
          </div>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default OurTeam;
