import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
import EmbeddedDevicesContent from "../../../content/services/EmbeddedDevices.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const EmbeddedDevices = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Deployment On Embedded Devices</title>
        <meta name="description" content="Explore the diverse applications of computer vision on embedded devices, including surveillance systems, smart home automation, industrial automation, agriculture and farming, traffic monitoring and management, retail analytics, object recognition, and augmented reality. Learn how computer vision enhances efficiency, safety, and user experiences across various domains when integrated into embedded systems." />
        <meta name="keywords" content="computer vision, embedded devices, surveillance systems, smart home automation, industrial automation, agriculture and farming, traffic monitoring and management, retail analytics, object recognition, augmented reality" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1 + " " + serviceContent.titlePart2}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}


















                      <br />
                      <h3><strong>Bring AI to the Edge with FainTech's Embedded Solutions</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we specialize in deploying AI models on <strong>embedded devices</strong>—small, specialized
                        hardware designed for specific tasks. From sensors to edge devices, we make it possible for deep learning models
                        to run efficiently where they’re needed the most.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>What is Deployment on Embedded Devices?</strong></h3>
                      <br />

                      <p>
                        Deployment on embedded devices means running AI models directly on compact, resource-constrained hardware like
                        microcomputers or microcontrollers. This allows AI to function at the <strong>edge</strong>, without relying on
                        powerful servers or cloud connections. Embedded deployments require optimizing models to ensure they work smoothly
                        with limited memory and processing power.
                      </p>

                      <img src="assets/img/services_images/rpi+jetsons 1.png" alt="Embedded AI with Raspberry Pi and Jetson devices" />

                      <hr />

                      <br />
                      <h3><strong>Benefits of Embedded Deployment</strong></h3>
                      <br />

                      <ul>
                        <li><strong>Real-Time Processing</strong>: Get instant insights directly at the source, without delay from cloud communications.</li>
                        <li><strong>Lower Costs</strong>: Avoid costly server infrastructure by processing data on-site.</li>
                        <li><strong>Increased Privacy</strong>: Keep sensitive data on the device, reducing the need for external transfers and improving privacy.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech for Embedded AI?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we design efficient AI models that fit the limitations of embedded devices, while ensuring
                        high performance. Whether you need real-time image processing, predictive maintenance, or smart sensors, our solutions
                        bring advanced AI capabilities directly to your hardware.
                      </p>

                      <p><strong>Contact us</strong> to learn how we can help deploy AI on your embedded devices, bringing intelligence right to the edge.</p>

















                    </p>
                  </div>

                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">

                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EmbeddedDevices;
