import React, {useContext} from "react";
import {MainContext} from "../../context/MainContext";

const introContent = {
  "en": "The specific steps may vary depending on the nature of your project and the consultancy you are working with. <b>It's important to establish a strong working relationship based on trust, clear communication, and a shared commitment to success.</b>",
  "no": "De spesifikke trinnene kan variere avhengig av prosjektets art og konsulentselskapet du jobber med. <b>Det er viktig å etablere et sterkt arbeidsforhold basert på tillit, tydelig kommunikasjon og en felles forpliktelse til suksess.</b>"
}
const approachContent = [
  {
    title: {
      "en": "Book Free Consultation",
      "no": "Bestill gratis konsultasjon"
    },
    descriptions: {
      "en": "This consultation is an opportunity to brainstorm together, discuss your goals, understand your product, and explore how we can help you achieve your development milestones.",
      "no": "Denne konsultasjonen er en mulighet til å idémyldre sammen, diskutere målene dine, forstå produktet ditt og utforske hvordan vi kan hjelpe deg med å nå dine utviklingsmål."
    },
    delayAnimation: "0"
  },
  {
    title: {
      "en": "Define the Scope of Work",
      "no": "Definer omfanget av arbeidet"
    },
    descriptions: {
      "en": "We outline the deliverables, timelines, and any constraints you have. This helps align our expectations and ensures a smooth collaboration.",
      "no": "Vi skisserer leveranser, tidslinjer og eventuelle begrensninger du har. Dette bidrar til å tilpasse forventningene våre og sikrer et smidig samarbeid."
    },
    delayAnimation: "100"
  },
  {
    title: {
      "en": "Establish communication channels",
      "no": "Etablere kommunikasjonskanaler"
    },
    descriptions: {
      "en": "Determine the preferred communication channels and frequency of updates. This could include regular meetings, progress reports, or a dedicated project management tool.",
      "no": "Bestem de foretrukne kommunikasjonskanalene og frekvensen av oppdateringer. Dette kan inkludere regelmessige møter, fremdriftsrapporter eller et dedikert prosjektstyringsverktøy."
    },
    delayAnimation: "200",
  },
  {
    title: {
      "en": "Collaborate and provide feedback",
      "no": "Samarbeid og gi tilbakemelding"
    },
    descriptions: {
      "en": "Collaboration and constructive communication are key to achieving the desired outcomes.",
      "no": "Samarbeid og konstruktiv kommunikasjon er nøkkelen til å oppnå de ønskede resultatene."
    },
    delayAnimation: "300",
  },
];

const Approach2 = () => {
  const {language,} = useContext(MainContext);
  return (
    <div>
      <div
        className="ptf-spacer"
        style={{ "--ptf-xxl": "2.625rem" }}
      ></div>
      <div dangerouslySetInnerHTML={{__html: introContent[language]}}/>
      <div
        className="ptf-spacer"
        style={{ "--ptf-xxl": "5.625rem" }}
      ></div>

      <ul className="ptf-process-steps">
        {approachContent.map((val, i) => (
          <li
            className="ptf-process-item"
            data-aos="fade"
            data-aos-delay={val.delayAnimation}
            key={i}
          >
            <h6 className="ptf-process-item__title">{val.title[language]}</h6>
            <p className="ptf-process-item__text">{val.descriptions[language]}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Approach2;
