import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Faq from "../../../components/faq/Faq";
import Approach2 from "../../../components/service/Approach2";
// import ImageSegmentationContent from "../../../content/services/ImageSegmentation.json"
import { MainContext } from "../../../context/MainContext";
import ServicesLabels from "../../../content/services/ServicesLabels.json";
import ServicesAllContent from "../../../content/services/ServicesAll.json";

const ImageSegmentation = () => {
  const currentPage = window.location.pathname.split("/").pop();
  const serviceContent = ServicesAllContent.find((service, i) => (service.link === currentPage))
  const { language, } = useContext(MainContext);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faintech | Image Segmentation</title>
        <meta name="description" content="Explore various applications of image segmentation, including medical imaging, object recognition, scene understanding, video analysis, satellite imaging, and industrial quality control. Learn how image segmentation technology contributes to different fields such as healthcare, robotics, surveillance, and environmental monitoring." />
        <meta name="keywords" content="image segmentation, medical imaging, object recognition, scene understanding, video analysis, satellite imaging, industrial quality control, healthcare, robotics, surveillance, environmental monitoring" />
        <meta name="author" content="Faintech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">{serviceContent.titlePart1 + " " + serviceContent.titlePart2}</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      {/* {serviceContent.descriptions[language]} */}

















                      <br />
                      <h3><strong>Discover the Power of Image Segmentation with FainTech</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we deliver specialized image segmentation solutions to meet your business needs.
                        Whether you're enhancing industrial quality control, optimizing agriculture, or improving traffic management,
                        our expertise in computer vision and AI helps you implement smarter, more efficient systems.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>How Does Image Segmentation Work?</strong></h3>
                      <br />

                      <p>
                        Image segmentation goes beyond simple object recognition by dividing an image into meaningful sections. This
                        allows machines to understand the exact location and boundaries of objects within an image, making it possible
                        to identify multiple elements in a single frame with great precision.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Motivational Example: Road Scene Segmentation</strong></h3>
                      <br />

                      <p>
                        Imagine a smart camera analyzing a road scene, identifying not just cars and pedestrians but also segmenting them
                        to understand their exact position on the road. This level of detail is crucial in traffic management, manufacturing
                        safety, and agricultural monitoring.
                      </p>

                      <p>
                        In a road scene, pedestrians might be colored purple, and vehicles might be colored red. These colors represent the
                        segmentation classes. Every pixel belonging to a pedestrian will be shaded purple, while those belonging to a vehicle
                        will be shaded red. This segmentation enables the system to understand not only what objects are present but also
                        their shape and exact location within the image.
                      </p>

                      <img src="assets/img/services_images/image_segmentation_road_scene.png" alt="Road Scene Segmentation Example" />

                      <hr />

                      <br />
                      <h3><strong>FainTech's Expertise Across Industries</strong></h3>
                      <br />

                      <hr />

                      <br />
                      <h4><strong>Industrial Quality Control</strong></h4>
                      <br />

                      <p>
                        In industrial settings, image segmentation ensures each part of the product is carefully inspected. By segmenting
                        different regions of an item, cameras can identify defects in specific areas, allowing for more precise quality control.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We create systems that segment and analyze each part of a product to detect defects
                          at a granular level, improving production speed and accuracy.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Image segmentation of products on an assembly line, with defect areas highlighted for further inspection.</p>

                      <hr />

                      <br />
                      <h4><strong>Agricultural Automation</strong></h4>
                      <br />

                      <p>
                        In agriculture, image segmentation allows farmers to monitor plant health with greater accuracy. By segmenting
                        individual plants and crops, farmers can assess growth, detect diseases, and monitor nutrient distribution, leading
                        to more informed decisions and improved yields.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: FainTech provides automated systems that use segmentation to evaluate plant health,
                          identify diseases, and optimize farming practices with precision.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Segmentation of healthy and diseased areas within a crop field, providing detailed analysis to support better crop management.</p>

                      <hr />

                      <br />
                      <h4><strong>Traffic Management and Smart Cities</strong></h4>
                      <br />

                      <p>
                        For traffic management, image segmentation plays a key role in analyzing complex road scenes. It allows smart
                        cameras to detect and separate different elements, such as vehicles, pedestrians, and road signs, giving cities
                        a detailed understanding of how road space is being used.
                      </p>
                      <ul>
                        <li>
                          <strong>What We Do</strong>: We build AI models that segment traffic scenes, helping cities improve traffic flow
                          and enhance road safety by providing clear, segmented data of all objects within a scene.
                        </li>
                      </ul>

                      <p><strong>Example</strong>: Segmentation of a busy intersection, with vehicles, pedestrians, and traffic signs clearly identified and separated for analysis.</p>

                      <hr />

                      <br />
                      <h3><strong>Why Choose FainTech?</strong></h3>
                      <br />

                      <p>
                        At <strong>FainTech</strong>, we don’t just offer AI solutions—we partner with you to build custom image segmentation
                        systems that meet your unique needs. Here’s why you should work with us:
                      </p>
                      <ul>
                        <li><strong>Custom Solutions</strong>: We design systems from scratch, ensuring they align with your specific requirements and environment.</li>
                        <li><strong>Latest Technology</strong>: Our solutions leverage advanced computer vision and AI technologies, providing exceptional accuracy and reliability.</li>
                        <li><strong>Expertise Across Industries</strong>: With proven experience in agriculture, industrial processes, and traffic management, we know how to deliver solutions that help your business thrive.</li>
                      </ul>

                      <hr />

                      <br />
                      <h3><strong>How FainTech Can Help You</strong></h3>
                      <br />

                      <p>
                        From the initial idea to a working prototype and final product, FainTech is with you at every step. We collaborate
                        with you, exploring data and building sophisticated solutions that solve real-world problems. Whether you need to
                        optimize production lines, monitor crop health, or improve road safety, we have the expertise to make it happen.
                      </p>

                      <hr />

                      <br />
                      <h3><strong>Get Started with FainTech Today</strong></h3>
                      <br />

                      <p>
                        Interested in how image segmentation can transform your operations? <strong>Contact us</strong> at FainTech to explore
                        how we can build the right solution for your business. Let’s work together to innovate and achieve success!
                      </p>

















                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}*/}
                  {/*></div>*/}
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour />  */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            {/*<div*/}
            {/*  className="ptf-spacer"*/}
            {/*  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}*/}
            {/*></div>*/}
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <p className="fz-18">
                      {/*{ImageSegmentationContent.description_long[language]}*/}
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  {/*<div*/}
                  {/*  className="ptf-spacer"*/}
                  {/*  style={{ "--ptf-xxl": "4.375rem" }}*/}
                  {/*></div>*/}
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/*<h3>{ServicesLabels.applications[language]}</h3>*/}
                    {/* <!--Spacer--> */}
                    {/*<div*/}
                    {/*  className="ptf-spacer"*/}
                    {/*  style={{ "--ptf-xxl": "1.875rem" }}*/}
                    {/*></div>*/}
                    {/*<p className="fz-18">*/}
                    {/*  {ImageSegmentationContent.applications.map((item, i) => (*/}
                    {/*      <p dangerouslySetInnerHTML={{__html: item[language]}}/>*/}
                    {/*    ))}*/}
                    {/*</p>*/}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Animated Block--> */}
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos-delay="0"
                  >
                    <h3>{ServicesLabels.our_approach[language]}</h3>
                    {/* <!--Spacer--> */}
                  </div>
                  {/* <!--Spacer--> */}

                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ImageSegmentation;
