import React, {memo} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CustomCarousel.css';

// Memoized component for each image
const CarouselImage = memo(({src, alt, className}) => (
  <img src={src} alt={alt} loading="lazy" className={className}/>
));

const CustomCarousel = memo(() => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Smooth transition speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Delay between slides
    arrows: false,
    fade: true, // Enable fade effect for smoother transitions
    adaptiveHeight: false,
    pauseOnHover: false,
    lazyLoad: 'ondemand', // Lazy load images to improve performance
  };

  return (
    <div className="carousel-background">
      <Slider {...settings}>

        <div className="carousel-slide">
          <CarouselImage
            src="/assets/img/carousel/5.jpg"
            alt="Background 5"
            className="fifth-image" // Position in the center
          />
          <div className="carousel-text">
            <h2>Vision-Driven Safety in Industry</h2>
            <p>Real-time monitoring of an industrial environment maintains safety standards, protecting workers and
              preventing violations</p>
          </div>
        </div>

        <div className="carousel-slide">
          <CarouselImage
            src="/assets/img/carousel/2.jpg"
            alt="Background 2"
            className="second-image" // Another class for different position
          />
          <div className="carousel-text">
            <h2>Visual Inspection for Quality Control</h2>
            <p>Automatically detects defects and misalignments with CAD model, increasing productivity</p>
          </div>
        </div>

        <div className="carousel-slide">
          <CarouselImage
            src="/assets/img/carousel/olives.jpg"
            alt="Background 3"
            className="third-image" // Position in the center
          />
          <div className="carousel-text">
            <h2>Smart Farming Solutions with Vision Systems</h2>
            <p>Optimizes costs through real-time monitoring of the production process</p>
          </div>
        </div>

        <div className="carousel-slide">
          <CarouselImage
            src="/assets/img/carousel/3.jpg"
            alt="Background 1"
            className="first-image"
          />
          <div className="carousel-text">
            <h2>Vision-based solutions to tackle traffic challenges</h2>
            {/*<p></p>*/}
          </div>
        </div>

        {/*Replace image*/}

        {/*<div className="carousel-slide">*/}
        {/*  <CarouselImage*/}
        {/*    src="/assets/img/carousel/4.1.jpg"*/}
        {/*    alt="Background 4"*/}
        {/*    className="fourth-image" // Position in the center*/}
        {/*  />*/}
        {/*  <div className="carousel-text">*/}
        {/*    <h2>Automated Solutions for Flawless Inspection</h2>*/}
        {/*    <p>Ensures labels and barcodes meet standards, minimizing packaging errors.</p>*/}
        {/*  </div>*/}
        {/*</div>*/}


      </Slider>
    </div>
  );
});

export default CustomCarousel;
